export const GetAllProjectListQuery = `
query GetAllProjectList{
  allProjectList{
    title
   project{
     _id
     title
     projectOwners {
      image {
        asset {
          url
        }
      }
      name
      designation
      email
      slack
    }
     url
     industryVertical{
      name
    }
    technologies{
      name
    }
    speakerNotes{
      title
      content
      _id
      lastUpdatedTime
      comments{
        id
        commentVal
        photoURL
      }
    }
     gcpProjectID
     clusterType{
       slug{
         current
       }
     }
     gcpZone
     gcpClusterId
     gcpNodeID
     gcpNodeCount
     screenshot{
       asset{
         url
       }
     }
     activationTime
     features{
      name
    }
    projectScreenshots{
      caption
      actualURL
      image {
        asset {
          url
        }
      }
    }
  
     overview
     gcpCannotDisable
   }
 }
 }`;
