import { getAllPowerPointListQuery } from "../../graphql/queries/pptQueries";
import { GetAllProjectListQuery } from "../../graphql/queries/projectQueries";
import { getAllVideosListQuery } from "../../graphql/queries/videoQueries";

const BASE_URL = "https://sanity-api-xi.vercel.app/api/default";

const variables = {
  now: new Date().toISOString(),
}

const fetchData = async (query) => {
  const res = await fetch(BASE_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables
    }),
  });
  return res.json();
};

export const getProjectList = () => fetchData(GetAllProjectListQuery);
export const getVideoList = () => fetchData(getAllVideosListQuery);
export const getPowerPointList = () => fetchData(getAllPowerPointListQuery)
