import { mutateData } from "../../../service/mutateService";
import { getData } from "../../../service/queryService";

type PayloadPostData = {
  title: string;
  content: string;
  lastUpdatedTime: string;
  comments: string[];
};

// add new notes
export const handleMutations = async (
  payload: PayloadPostData,
  projectId: string
) => {
  const mutationPayload = {
    mutations: [
      {
        create: {
          _type: "notes",
          ...payload,
        },
      },
    ],
  };
  try {
    await mutateData(mutationPayload);

    // extract noteId of newly created note
    const noteIdQuery = `*[title match "${payload.title}"]`;
    const noteQueryData = await getData(noteIdQuery);
    const noteId = noteQueryData.result[0]._id;

    // Get existing speakerNotes from the project
    const projectDataQuery = `*[ _id == "${projectId}" ]{speakerNotes}`;
    const projectQueryData = await getData(projectDataQuery);
    const existingSpeakerNotes = projectQueryData.result[0].speakerNotes || [];

    const projectMutationPayload = {
      mutations: [
        {
          patch: {
            id: projectId,
            set: {
              speakerNotes: [
                {
                  _type: "reference",
                  _ref: noteId,
                  _key: `${noteId}_key_${Math.random()}`,
                },
                ...existingSpeakerNotes,
              ],
            },
          },
        },
      ],
    };
    await mutateData(projectMutationPayload);
  } catch (error) {
    console.log("Error:", error);
  }
};

// edit existing notes
export const handleUpdateNote = async (payload: any, projectId: string) => {
  try {
    // Retrieve the existing speakerNotes from the project
    const projectDataQuery = `*[ _id == "${projectId}" ]{speakerNotes}`;
    const projectQueryData = await getData(projectDataQuery);
    const existingSpeakerNotes = projectQueryData.result[0].speakerNotes || [];

    // Find the index of the note within the speakerNotes array
    const noteIndex = existingSpeakerNotes.findIndex(
      (note: any) => note._ref === payload._id
    );

    if (noteIndex !== -1) {
      // Create a new array of speakerNotes with the updated note
      const updatedSpeakerNotes = [
        ...existingSpeakerNotes.slice(0, noteIndex),
        {
          _type: "reference",
          _ref: payload._id,
          _key: `${payload._id}_key_${Math.random()}`,
        },
        ...existingSpeakerNotes.slice(noteIndex + 1),
      ];

      // Update the speakerNotes field in the projects schema
      const mutationPayload = {
        mutations: [
          {
            patch: {
              id: projectId,
              set: {
                speakerNotes: updatedSpeakerNotes,
              },
            },
          },
        ],
      };
      await mutateData(mutationPayload);

      //update the fields of the note
      const noteMutationPayload = {
        mutations: [
          {
            patch: {
              id: payload._id,
              set: {
                title: payload.title,
                content: payload.content,
                lastUpdatedTime: payload.lastUpdatedTime,
              },
            },
          },
        ],
      };
      await mutateData(noteMutationPayload);
    } else {
      console.log("Note not found in speakerNotes array!");
    }
  } catch (error) {
    console.log("Error updating note:", error);
  }
};

// delete notes
export const handleDeleteNoteMutation = async (
  noteId: string,
  projectId: string
) => {
  try {
    // Fetch the comments associated with the note
    const noteQuery = `*[ _id == "${noteId}" ]{ comments[] }`;
    const noteQueryData = await getData(noteQuery);
    const commentsToDelete = noteQueryData.result[0].comments;

    // Remove the note from the `speakerNotes` field of the project
    const projectDataQuery = `*[ _id == "${projectId}" ]{speakerNotes}`;
    const projectQueryData = await getData(projectDataQuery);
    const existingSpeakerNotes = projectQueryData.result[0].speakerNotes || [];

    // Find the index of the note in the existing speakerNotes array
    const noteIndex = existingSpeakerNotes.findIndex(
      (note: any) => note._ref === noteId
    );

    if (noteIndex !== -1) {
      const updatedSpeakerNotes = [...existingSpeakerNotes];
      updatedSpeakerNotes.splice(noteIndex, 1);

      const removeNoteFromProjectPayload = {
        mutations: [
          {
            patch: {
              id: projectId,
              set: {
                speakerNotes: updatedSpeakerNotes,
              },
            },
          },
        ],
      };
      await mutateData(removeNoteFromProjectPayload);
    }

    // Delete the note

    const deleteNotePayload = {
      mutations: [
        {
          delete: {
            id: noteId,
          },
        },
      ],
    };
    await mutateData(deleteNotePayload);

    // Delete the associated comments
    const commentsMutationPayload = {
      mutations: commentsToDelete.map((commentId: any) => ({
        delete: {
          id: commentId._ref,
        },
      })),
    };
    await mutateData(commentsMutationPayload);
  } catch (error) {
    console.log("Error deleting note:", error);
  }
};

// add comments
export const handleUpdateComment = async (payload: any, noteId: string) => {
  const commentPayload = {
    mutations: [
      {
        create: {
          _type: "comment",
          commentTitle: payload.commentTitle,
          id: payload.id,
          commentVal: payload.commentVal,
          photoURL: payload.photoURL,
        },
      },
    ],
  };

  try {
    await mutateData(commentPayload);

    // extract commentId of newly created comment
    const commentQuery = `*[commentVal match "${payload.commentVal}"]`;
    const commentQueryData = await getData(commentQuery);
    const commentId = commentQueryData.result[0]._id;

    // Get existing notes from the notes
    const noteQuery = `*[ _id == "${noteId}" ]{comments}`;
    const noteQueryData = await getData(noteQuery);
    const existingComments = noteQueryData.result[0].comments || [];

    const noteMutatioPayload = {
      mutations: [
        {
          patch: {
            id: noteId,
            set: {
              comments: [
                {
                  _type: "reference",
                  _ref: commentId,
                  _key: `${commentId}_key_${Math.random()}`,
                },
                ...existingComments,
              ],
            },
          },
        },
      ],
    };
    await mutateData(noteMutatioPayload);
  } catch (error) {
    console.log("Error updating comment:", error);
  }
};
