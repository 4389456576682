export const getAllPowerPointListQuery = `
query GetAllPowerPoint{
    allPowerpoint{
      title
      description
      imageUrl{
        asset{
          url
        }
      }
      ppt_url
      
    }
  }
`