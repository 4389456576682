export const updateTime = () => {
  const d = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const uDate = d.getUTCDate();
  const uMonth = months[d.getUTCMonth()];
  const uYear = d.getUTCFullYear();

  const uHour = d.getUTCHours();
  const uMinutes = d.getUTCMinutes();

  return `Last Update: ${uDate} of ${uMonth}, ${uYear} at ${uHour}:${uMinutes} GMT`;
};
