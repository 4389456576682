import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { useAuth } from './Auth/AuthContext'

const PrivateRoute = ({ component: Component, location, ...rest }) => {

  const {user} = useAuth();
  if(!user){
    navigate("/app/login")
    return null
  }

  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute