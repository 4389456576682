import React, { useRef, useState, useEffect } from "react";
import "./Chat.css";
import ReactMarkdown from "react-markdown";
import minimize from "../../images/Minimize.svg";
import chatHeaderIcon from "../../images/chat-icon.svg";
import sendIcon from "../../images/Send.svg";
import chatIconMobile from "../../images/chat-icon-mobile.svg";
import axios from "axios";

const Chat = (props) => {
  const [messages, setMessages] = useState([]);
  const [messageLoading, setMessageLoading] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const bottomRef = useRef(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const scrollToBottom = () => {
    const div = document.getElementById("messages");
    div.scrollTop = div.scrollHeight;
  };

  const firebaseURL =
    "https://alan-suite-backend-dev-vlrd2qrw7a-el.a.run.app/api/chat/query?name=onestopdemo&organizationName=techolution";
  const getHistoryURL = `https://alan-suite-backend-dev-vlrd2qrw7a-el.a.run.app/api/chat/history?name=onestopdemo&organizationName=techolution`;

  const handleKeyDown = (event) => {
    const query = document.getElementById("chat_input").value;
    if (
      query !== "" &&
      (event.key === "Enter" || event.target.id === "send_svg")
    ) {
      setMessageLoading(true);
      setMessages([...messages, { role: "user", content: query }]);
      setTimeout(() => {
        scrollToBottom();
      }, 100);

      document.getElementById("chat_input").value = "";
      const reqBody = {
        role: "user",
        prompt: query,
      };
      try {
        const eventSource = new EventSource(
          `https://alan-suite-backend-dev-vlrd2qrw7a-el.a.run.app/api/chat/streamResponse?name=onestopdemo&organizationName=techolution&prompt=${query}`,
          { withCredentials: true }
        );
        let streamedresponse = { role: "assistant", content: "" };
        eventSource.onmessage = (event) => {
          //console.log('event', event.data);
          if (event.data === "[DONE]") {
            eventSource.close();
          } else {
            const data = JSON.parse(event.data);
            if (data?.choices[0]?.delta?.content) {
              setMessageLoading(false);
              streamedresponse.content += data?.choices[0].delta.content;
              setMessages([
                ...messages,
                { role: "user", content: query },
                streamedresponse,
              ]);
              scrollToBottom();
            }
          }
        };
      } catch (error) {
        setMessageLoading(false);
        console.log(error);
      }
    }
  };

  const getChatHistory = () => {
    try {
      const requestOptions = {
        method: "GET",
        withCredentials: true,
      };
      axios
        .get(getHistoryURL, requestOptions)
        .then((response) => {
          const data = response.data;
          if (data.length > 0) {
            setMessages(data);
            setTimeout(() => {
              scrollToBottom();
            }, 100);
          } else {
            setShowWelcomeMessage(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      setMessageLoading(false);
      console.log(error);
    }
  };

  const openChatWindow = () => {
    setIsChatOpen(true);
  };
  const closeChatWindow = () => {
    setIsChatOpen(false);
  };
  useEffect(() => {
    getChatHistory();
  }, []);

  React.useEffect(() => {
    if (isChatOpen && props.isMobile) {
      window.fullpage_api?.setAllowScrolling(false);
    } else {
      window.fullpage_api?.setAllowScrolling(true);
    }
  }, [isChatOpen]);

  const formatDate = (date) => {
    const dateFormat = new Date(date);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Date(dateFormat).toLocaleDateString(
      "en-US",
      options
    );

    // Split the formatted date into day, month, and year parts
    const [month, day] = formattedDate.split(" ");

    const hours = String(dateFormat.getHours()).padStart(2, "0");
    const minutes = String(dateFormat.getMinutes()).padStart(2, "0");

    // Return the formatted date with uppercase month abbreviation and desired format
    return `${day.split(",")[0]} ${month}, ${hours}:${minutes}`;
  };

  return (
    <>
      {!isChatOpen && (
        <button className="chat-icon-main" onClick={() => openChatWindow()}>
          <svg
            id="chat-svgrepo-com"
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45.015"
            viewBox="0 0 60 60.015"
            className="chat-icon_svg"
            style={{ fill: props.isFirstPage ? "white" : "black" }}
          >
            <path
              id="Path_11"
              data-name="Path 11"
              d="M42.007,0h-24a18.021,18.021,0,0,0-18,18V32a18.021,18.021,0,0,0,18,18h1.781a25.916,25.916,0,0,1,18.446,7.641l1.876,1.876a1.7,1.7,0,0,0,2.9-1.2V49.973A18.023,18.023,0,0,0,60.007,32V18A18.02,18.02,0,0,0,42.007,0Zm16,32a16.007,16.007,0,0,1-15,15.949V44a1,1,0,0,0-2,0V57.586l-1.359-1.359A27.9,27.9,0,0,0,19.788,48H18.007a16.019,16.019,0,0,1-16-16V18a16.019,16.019,0,0,1,16-16h24a16.019,16.019,0,0,1,16,16Z"
              transform="translate(-0.007)"
            />
            <path
              id="Path_12"
              data-name="Path 12"
              d="M17.007,21a4,4,0,1,0,4,4A4,4,0,0,0,17.007,21Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,17.007,27Z"
              transform="translate(-0.007)"
            />
            <path
              id="Path_13"
              data-name="Path 13"
              d="M30.007,21a4,4,0,1,0,4,4A4,4,0,0,0,30.007,21Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,30.007,27Z"
              transform="translate(-0.007)"
            />
            <path
              id="Path_14"
              data-name="Path 14"
              d="M43.007,21a4,4,0,1,0,4,4A4,4,0,0,0,43.007,21Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,43.007,27Z"
              transform="translate(-0.007)"
            />
          </svg>
          <img
            src={chatIconMobile}
            className="chat-icon_mobile"
            alt="Chat icon"
          />
        </button>
      )}
      <div
        className={`mainChatContainer ${
          isChatOpen ? "chat-window_active" : ""
        }`}
        ref={bottomRef}
      >
        <div className="chat-widow_header">
          <div className="chat-window_header-text">
            <div className="chat-header_icon-container">
              <img
                src={chatHeaderIcon}
                className="chat-header_icon"
                alt="chat-logo"
              />
            </div>
            <div>Chat with us</div>
          </div>
          <div className="chat-window-close" onClick={() => closeChatWindow()}>
            <img src={minimize} className="minimize_icon" alt="minimize-logo" />
          </div>
        </div>
        <div className="chat-window">
          <div className="messages-class" id="messages">
            {messages.length
              ? messages.map((message) => (
                  <div className="message-wrapper">
                    <div
                      className={
                        "message " +
                        (message.role == "assistant" ? "assistant" : "user")
                      }
                    >
                      <ReactMarkdown>{message.content}</ReactMarkdown>
                    </div>
                    <span
                      className={`date_time ${
                        message.role == "assistant" ? "assistant" : "user"
                      }`}
                    >
                      {message?.time
                        ? formatDate(message?.time)
                        : formatDate(Date.now())}
                    </span>
                  </div>
                ))
              : showWelcomeMessage && (
                  <div className="message assistant">How may I help you?</div>
                )}
            {messageLoading && (
              <div className="message assistant">
                <div class="message_loader">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
          <input
            type="text"
            id="chat_input"
            onKeyDown={handleKeyDown}
            className="prompt"
            placeholder="Enter message"
          ></input>
          <div className="send_button" onClick={handleKeyDown}>
            <img
              id="send_svg"
              src={sendIcon}
              className="send_icon"
              alt="send icon"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
