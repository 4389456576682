import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

const ScreenShotTab = ({ project }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  var imagesUrl = project?.projectScreenshots
    ? project.projectScreenshots.map((values) => values?.image?.asset?.url)
    : "";
  return (
    <div className="tabSection">
      <div className="screenshots">
        {project?.projectScreenshots ? (
          project.projectScreenshots.map((projectScreenshot, key) => (
            <div key={key} style={{ position: "relative" }}>
              <figure className="screenshot" key={projectScreenshot?.caption}>
                <img
                  width={500}
                  src={projectScreenshot?.image?.asset?.url}
                  onClick={() => {
                    openImageViewer(key);
                  }}
                  title="Click to view this image"
                ></img>
                <figcaption>
                  {projectScreenshot?.caption
                    ? projectScreenshot.caption
                    : "Screenshot " + (key + 1)}{" "}
                  {projectScreenshot?.actualURL ? (
                    <a
                      className="screeshot-link"
                      href={projectScreenshot?.actualURL}
                      target="_blank"
                    >
                      View Live
                    </a>
                  ) : (
                    ""
                  )}
                </figcaption>
              </figure>
              {isViewerOpen && (
                <div style={{ position: "fixed", zIndex: "6" }}>
                  <ImageViewer
                    src={imagesUrl}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={true}
                    backgroundStyle={{
                      backgroundColor: "rgba(0,0,0,0.4)",
                    }}
                    closeOnClickOutside={true}
                    closeComponent={
                      <CloseRoundedIcon
                        style={{
                          fontSize: "60px",
                          color: "#f3f3f3",
                          background: "#1c1b1a",
                          borderRadius: "var(--space-5)",
                        }}
                      />
                    }
                    leftArrowComponent={
                      <KeyboardArrowLeftRoundedIcon
                        style={{
                          fontSize: "75px",
                          color: "#f3f3f3",
                        }}
                      />
                    }
                    rightArrowComponent={
                      <KeyboardArrowRightRoundedIcon
                        style={{
                          fontSize: "75px",
                          color: "#f3f3f3",
                        }}
                      />
                    }
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="nocontent-msg">No screenshot to show.</div>
        )}
      </div>
    </div>
  );
};

export default ScreenShotTab;
