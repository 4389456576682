const url = "https://sanity-api-xi.vercel.app/api/mutate";

export const mutateData = async (payload) => {
  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return res.json();
};
