import React, { useEffect, useState } from "react";
import "./Footer.css";
import logo from "../../images/logo.svg";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { Alert, Snackbar } from "@mui/material";

const Footer = ({ projectNames, setSectionState }) => {
  const qLinksData = {
    "Enterprise Cloud":
      "https://www.techolution.com/our-expertise/enterprise-cloud",
    "Product Innovation":
      "https://www.techolution.com/our-expertise/product-innovation",
    "Real World AI": "https://www.techolution.com/our-expertise/real-world-ai",
    "Google Cloud Partner": "https://www.techolution.com/google-cloud",
    "User Experience Design":
      "https://www.techolution.com/our-expertise/product-innovation/",
    "Co-Create / App Development": "https://www.techolution.com/co-create",
    "Sanity Studio": "https://one-stop.sanity.studio/",
  };
  const qLinks = Object.keys(qLinksData).map((values, key) => (
    <div key={key}>
      <a
        className="footer-list-item"
        href={qLinksData[values]}
        target="_blank"
        rel="noopener noreferrer"
      >
        {values}
      </a>
    </div>
  ));

  const projList = Object.values(projectNames)?.map((values, key) => {
    const [title, setTitle] = useState(values?.title);
    const [shouldScroll, setShouldScroll] = useState(false);

    const handleClick = () => {
      setTitle(values?.title);
      setShouldScroll(true);
      setSectionState("demo");
    };
    useEffect(() => {
      if (shouldScroll) {
        document.getElementById(title + "-id").scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setShouldScroll(false);
      }
    }, [shouldScroll, title]);

    return (
      <div key={key}>
        <div className="footer-list-item" onClick={handleClick}>
          {values?.title}
        </div>
      </div>
    );
  });

  const contact = {
    0: {
      name: "Amit Gaurollu",
      email: "amit.gaurollu@techolution.com",
      desig: "Program Manager",
      slack: "@amit.gaurollu",
    },
    1: {
      name: "Sabith Pocker",
      email: "sabith@techolution.com",
      desig: "Web Admin",
      slack: "@sabithpocker",
    },
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const contactList = Object.values(contact).map((values, key) => (
    <div className="contact-card" key={key}>
      <b>{values["name"]}</b>
      <br />
      <span>{values["desig"]}</span>
      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: "var(--font-sm)",
        }}
      >
        {values["email"]}
        <ContentCopyRoundedIcon
          onClick={() => {
            navigator.clipboard.writeText(values["email"]);
            setSnackbarOpen(true);
          }}
          sx={{ cursor: "pointer" }}
        />
      </div>
      <span
        style={{ fontSize: "var(--font-sm)" }}
        onClick={() => {
          window.open(
            `https://slack.com/app_redirect?channel=${values["slack"]}`,
            "_blank"
          );
        }}
      >
        Slack Id - <span style={{ cursor: "pointer" }}>{values["slack"]}</span>
      </span>
      <br />
      <br />
    </div>
  ));

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="projectContainer" style={{ alignItems: "flex-end" }}>
      <div className="footer">
        <div className="footerSection projectsFooter">
          <div className="titlesSummary">Projects</div>
          <div
            className="projectsFooter-list"
            style={{ display: "flex", flexFlow: "column wrap", height: "100%" }}
          >
            {projList}
          </div>
        </div>
        <div className="footerSection img">
          <img
            src={logo}
            alt="techo_logo"
            onClick={() =>
              window.open("https://www.techolution.com/", "_blank")
            }
          />
        </div>
        <div className="footerSection qLinksFooter">
          <div className="titlesSummary">Quick Links</div>
          <div
            className="qLinksFooter-list"
            style={{ display: "flex", flexFlow: "column wrap", height: "100%" }}
          >
            {qLinks}
          </div>
        </div>
        <div className="footerSection contactFooter">
          <div className="titlesSummary">Contact</div>
          <div
            className="contactFooter-list"
            style={{ display: "flex", flexFlow: "column wrap", height: "100%" }}
          >
            {contactList}
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: "50px" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Email copied to clipboard!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Footer;
