import React, { useContext, useEffect, useState } from "react";
const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;
import "react-quill/dist/quill.snow.css";
import { updateTime } from "../../../../helper";
import { handleMutations, handleUpdateNote } from "./helper";
import { LinearProgress } from "@mui/material";
import { UpdateDataContext } from "../../../Dashboard";

const NoteEditor = ({
  activeIndex,
  addNote,
  setAddNote,
  notes,
  reactQuillRef,
  setActiveIndex,
  project,
  noteTitle,
  setNoteTitle,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateData = useContext(UpdateDataContext);
  const [noteContent, setNoteContent] = useState("");

  useEffect(() => {
    setNoteContent(notes ? notes[activeIndex]?.content : "");
  }, [activeIndex, notes]);

  const handleNotesSubmit = async (arg, type) => {
    setIsLoading(true);
    const updatedTime = updateTime();
    const avar = reactQuillRef.current?.makeUnprivilegedEditor(arg);
    const contentPlainText = avar?.getText()?.replace(/\n/g, "");

    if (type === "save") {
      const updatedNote = {
        ...notes[activeIndex],
        title: noteTitle,
        content: contentPlainText,
        lastUpdatedTime: updatedTime,
      };

      await handleUpdateNote(updatedNote, project?._id)
        .then(() => updateData())
        .then(() => {
          setIsLoading(false);
        });
    } else {
      const newNote = {
        title: noteTitle,
        content: contentPlainText,
        lastUpdatedTime: updatedTime,
        comments: [],
      };
      await handleMutations(newNote, project?._id)
        .then(() => updateData())
        .then(() => {
          setIsLoading(false);
        });
    }
    setActiveIndex(null);
    setNoteTitle("");
  };

  return (
    <>
      {isLoading && (
        <LinearProgress
          sx={{
            marginBottom: 2,
            background: "#da5362",
            "& .MuiLinearProgress-bar1Indeterminate": {
              backgroundColor: "#A2A2A2",
            },
            "& .MuiLinearProgress-bar2Indeterminate": {
              backgroundColor: "#da5362",
            },
          }}
        />
      )}
      {/* notes header */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="titlesSummary">Write Notes Here</div>
        <button
          className="saveNote-button"
          style={{
            display:
              (activeIndex !== null || addNote) && noteTitle
                ? "inline-block"
                : "none",
          }}
          onClick={() => {
            const type = activeIndex !== null ? "save" : "add note";
            const editorValue = reactQuillRef.current?.getEditor();
            handleNotesSubmit(editorValue, type);
          }}
        >
          {activeIndex !== null && !addNote
            ? "Save"
            : addNote
            ? "Add Note"
            : ""}
        </button>
      </div>

      {/* note title */}
      <input
        type="text"
        value={noteTitle}
        onChange={(e) => setNoteTitle(e.target.value)}
        placeholder="Enter Note Title"
        className="notes-styles title-input"
      />
      {/* editor */}
      <div className="descriptions">
        <ReactQuill
          ref={reactQuillRef}
          className="notes-styles"
          value={noteContent}
          onChange={setNoteContent}
          id="notes-id"
          placeholder="Click on any of the notes to view its content or comments"
          onFocus={() => {
            activeIndex != null ? setAddNote(false) : setAddNote(true);
          }}
        />
      </div>
    </>
  );
};

export default NoteEditor;
