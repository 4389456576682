import * as React from "react";
import * as styles from "./videoCard.module.css";
import playIconWHite from "../../../images/Play.svg";

const VideoCard = (props) => {
  const videoCradClicked = (videoUrl) => {
    props.playVideoHandler(videoUrl, props.videoIndex);
  };
  return (
    <div
      className={`${styles.card} ${props.className}`}
      onClick={() => videoCradClicked(props.details.video)}
    >
      <div style={{ position: "relative" }} className="thumbnail-container">
        <img
          className={`${styles.videoPreviewimage} thumbnail-img`}
          src={props.details.imageUrl?.asset?.url}
        />
        <div className={`${styles.playBtn} thumbnail-play-icon`}>
          <img
            className={`${styles.playIcon} thumbnail-play-icon-btn`}
            src={playIconWHite}
          />
        </div>
      </div>
      <div className="video-card__content">
        <div className={`${styles.videoTitle} video-card__title`}>
          {props.details.title}
        </div>
        {props?.subTitle && (
          <div className="video-card__subtitle">{props.details.sub_title}</div>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
