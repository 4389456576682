import React from "react";
import { Buttons, GetStatus } from "./ProgressBtn";
const SummaryTab = ({
  project,
  updateNode,
  isProjectLoading,
  projectStatus,
}) => {
  const shouldRenderButtons =
    ["RUNNING", "TERMINATED"].includes(projectStatus[project?.title]) ||
    project?.gcpCannotDisable;

  return (
    <div className="tabSection" id="summary-tab">
      <div className="section1">
        <div className="descriptions" id="project-description">{project?.overview}</div>
        <div className="titlesSummary">Industries/Domain</div>
        <div className="descriptions">
          <div className="filter">
            {project?.industryVertical?.name ? (
              <div className="filterItem">
                {project?.industryVertical?.name}
              </div>
            ) : (
              "Not Available"
            )}
          </div>
        </div>
        <div className="titlesSummary">Technologies used</div>
        <div className="descriptions">
          <div className="filter">
            {project?.technologies?.length > 0
              ? project?.technologies?.map((item, index) => (
                  <div className="filterItem" key={index}>
                    {item?.name}
                  </div>
                ))
              : "Not Available"}
          </div>
        </div>
        {shouldRenderButtons ? (
          <Buttons
            project={project}
            isProjectLoading={isProjectLoading}
            updateNode={updateNode}
          />
        ) : (
          <GetStatus project={project} projectStatus={projectStatus} />
        )}
      </div>
      <div className="section2">
        <div className="img-container">
          <img src={project?.screenshot?.asset?.url}></img>
        </div>
        <p>{project?.title}</p>
      </div>
    </div>
  );
};

export default SummaryTab;
