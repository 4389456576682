import React, { useState } from 'react'
import Videos from './Videos'
import PPTdeck from './PPTdeck';

const overrideProjectContaierStyle ={
  flexDirection: "column", 
  justifyContent: "flex-start",
  scrollSnapAlign: "none"
}

const Assets = ({videos, ppt}) => {
  const [sectionState, setSectionState] =useState('videos')
  const sectionList = ["Videos", "PPT Deck"];
  const sections = sectionList.map((values, key) => (
    <div
      className={sectionState === values.toLowerCase() ? "selectedTab" : "tab"}
      key={key}
      onClick={() => setSectionState(values.toLowerCase())}
    >
      {values.toUpperCase()}
    </div>
  ));



  return (
    <>
    {sectionState === "videos" && (
        <div
          className="projectContainer"
          style={overrideProjectContaierStyle}
        >
          <div className="sectionsHeader" style={{margin:"20px 0px", justifyContent: "center"}}>{sections}</div>
          <Videos videos={videos} />
        </div>
      )}
      {sectionState === "ppt deck" && (
        <div
          className="projectContainer"
          style={overrideProjectContaierStyle}
        >
          <div className="sectionsHeader" style={{margin:"20px 0px", justifyContent: "center"}}>{sections}</div>
          <PPTdeck pptData={ppt} />
        </div>
      )}
      </>
  )
}

export default Assets