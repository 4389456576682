import { CircularProgress } from "@mui/material";
import React from "react";
export const GetStatus = ({ project, projectStatus }) => {
  const isReconcilingOrStopping = [
    "RECONCILING",
    "STOPPING",
    "STAGING",
  ].includes(projectStatus[project?.title]);
  const message = isReconcilingOrStopping
    ? `Please wait. Getting updated project status. ${
        project?.activationTime
          ? `This may take up to ${project?.activationTime} minutes`
          : ""
      }`
    : "Failed to fetch project status.";

  return (
    <div className="loadingContainer">
      <div className="loading">{message}</div>
      {isReconcilingOrStopping && (
        <CircularProgress
          style={{
            color: "#ec843f",
            width: "var(--space-4)",
            height: "var(--space-4)",
          }}
        />
      )}
    </div>
  );
};

export const Buttons = ({ project, isProjectLoading, updateNode }) => {
  const { title, url, isEnabled, gcpCannotDisable, activationTime } = project;

  return isEnabled || gcpCannotDisable ? (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "var(--space-2) var(--space-2)",
      }}
    >
      <button
        className="viewDemo"
        disabled={isProjectLoading[title]}
        onClick={() => window.open(url, "_blank")}
      >
        {isProjectLoading[title] ? "Enabling Project..." : "View Demo"}
      </button>
      {!isProjectLoading[title] && (
        <>
          <button
            className={
              gcpCannotDisable ? "disableBtndisabled" : "disableProject"
            }
            disabled={gcpCannotDisable}
            onClick={(event) => {
              updateNode(project, false);
              event.stopPropagation();
            }}
          >
            Disable Project
            <div className="disableBtnStatus">
              {gcpCannotDisable
                ? "This Project cannot be Disabled"
                : "Click to Disable this Project"}
            </div>
          </button>
        </>
      )}
    </div>
  ) : (
    <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
      <button
        className="enableBtn"
        disabled={isProjectLoading[title]}
        onClick={(event) => {
          updateNode(project, true);
          event.stopPropagation();
        }}
      >
        {isProjectLoading[title]
          ? "Disabling Project..."
          : `Enable Project (Approx ${activationTime} Mins)`}
      </button>
    </div>
  );
};
