import { ArrowDropDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { UpdateDataContext } from "../../../Dashboard";
import { handleUpdateComment } from "./helper";

const Comments = ({ notes, activeIndex, user, projectName }) => {
  const [commentVal, setCommentVal] = useState("");
  const [loading, setLoading] = useState(false);
  const updateData = useContext(UpdateDataContext);

  const handleCommentSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const noteToUpdate = notes[activeIndex];
    const commentPayload = {
      commentTitle: `Comment by ${user?.displayName} on ${projectName} `,
      id: user?.email,
      commentVal: commentVal,
      photoURL: user?.photoURL,
    };
    try {
      await handleUpdateComment(commentPayload, noteToUpdate?._id)
        .then(() => updateData())
        .then(() => {
          setLoading(false);
          setCommentVal("");
        });
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  return (
    <>
      {/* header */}
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDown />}>
          <div className="titlesSummary">
            Comments {notes ? notes[activeIndex]?.comments?.length : ""}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="descriptions">
            {/* comment input */}
            <form
              className="commentParent-class"
              style={{ display: activeIndex != null ? "flex" : "none" }}
              onSubmit={handleCommentSubmit}
            >
              <label>
                <Avatar
                  alt="profile image"
                  imgProps={{ margin: "auto" }}
                  src={user?.photoURL}
                />
              </label>
              <input
                type="text"
                placeholder="Add a comment"
                name="Comment"
                value={commentVal}
                onChange={(e) => setCommentVal(e.target.value)}
              />
              <div className="comment-buttons">
                <button
                  type="submit"
                  disabled={!commentVal}
                  style={{ cursor: commentVal ? "pointer" : "not-allowed" }}
                >
                  Post
                </button>
                <button
                  style={{ cursor: commentVal ? "pointer" : "not-allowed" }}
                  onClick={() => setCommentVal("")}
                  disabled={!commentVal}
                >
                  Clear
                </button>
              </div>
            </form>
            <p style={{ display: activeIndex != null ? "none" : "block" }}>
              No comments to view. Click on any of the notes.
            </p>
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <CircularProgress sx={{ color: "#da5362" }} />
              </div>
            )}
            {/* comments container */}
            <div className="allComments-container">
              {notes &&
                notes[activeIndex]?.comments?.map((comment, key) => (
                  <div
                    key={key}
                    style={{
                      display:
                        notes[activeIndex]?.comments?.length > 0
                          ? "flex"
                          : "none",
                      alignItems: "center",
                      gap: "var(--space-2) var(--space-2)",
                    }}
                  >
                    <Avatar
                      alt="profile image"
                      imgProps={{ margin: "auto" }}
                      src={comment?.photoURL || ""}
                      title={comment?.id}
                    />
                    <span>{comment?.commentVal}</span>
                    <br />
                  </div>
                ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Comments;
