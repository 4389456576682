import React, { useState } from "react";
import "./DetailView.css";
import ScreenShotTab from "./Tabs/ScreenShotTab";
import SummaryTab from "./Tabs/SummaryTab";
import FeaturesTab from "./Tabs/FeaturesTab";
import TeamInfo from "./Tabs/TeamInfo";
import SpeakerNotes from "./Tabs/SpeakerNotes/SpeakerNotes";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from '@mui/material/styles';


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    fontWeight: 400,
    padding: '6px 10px'
  },
}));

const DetailView = ({
  user,
  projects,
  updateNode,
  isProjectLoading,
  projectStatus,
}) => {
  const innerTabs = [
    "Summary",
    "Features",
    "Team Info",
    "Speaker Notes",
    "Screenshots",
  ];

  const [selectedTab, setSelectedTab] = useState(
    Array(projects && projects.length).fill(innerTabs[0])
  );

  const handleTabClick = (index, item) => {
    const newSelectedTabs = [...selectedTab];
    newSelectedTabs[index] = item;
    setSelectedTab(newSelectedTabs);
  };

  return (
    <div>
      {projects &&
        projects.map((project, index) => (
          <div className="projectContainer" key={index}>
            <div className="projDetailsContainer" id={project?.title + "-id"}>
              <div className="detailsheader">
                <div className="section1">
                  <div className="titledetailview">
                    {project?.title?.toUpperCase()}{" "}
                    <span className="edit-icon" onClick={()=>{window.open(`https://one-stop.sanity.studio/desk/project;${project._id}`)}}>
                      <LightTooltip title="Edit" placement="top">
                        <BorderColorOutlinedIcon fontSize="large" />
                      </LightTooltip>
                    </span>
                  </div>
                  <div className="tabList">
                    {innerTabs.map((item, tabIndex) => {
                      const isSelected = item === selectedTab[index];
                      const tabClassName = isSelected ? "selectedTab" : "tab";
                      const screenshotCount =
                        item === "Screenshots"
                          ? project?.projectScreenshots?.length ?? 0
                          : null;
                      const tabText =
                        screenshotCount !== null
                          ? `${item.toUpperCase()} (${screenshotCount})`
                          : item.toUpperCase();

                      return (
                        <div
                          className={tabClassName}
                          key={tabIndex}
                          onClick={() => handleTabClick(index, item)}
                        >
                          {tabText}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {selectedTab[index] === "Summary" && (
                <SummaryTab
                  project={project}
                  updateNode={updateNode}
                  isProjectLoading={isProjectLoading}
                  projectStatus={projectStatus}
                />
              )}
              {selectedTab[index] === "Features" && (
                <FeaturesTab project={project} />
              )}
              {selectedTab[index] === "Team Info" && (
                <TeamInfo project={project} projects={projects} />
              )}
              {selectedTab[index] === "Speaker Notes" && (
                <SpeakerNotes project={project} user={user} />
              )}
              {selectedTab[index] === "Screenshots" && (
                <ScreenShotTab project={project} />
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default DetailView;
