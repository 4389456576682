export var menuStyle = {
    bmBurgerBars: {
      background: "#DA5362",
      height: "3px",
      borderRadius: "2px",
    },
    bmBurgerBarsHover: {
      background: "#ffffff",
      opacity: "1",
    },
    bmCrossButton: {
      top: "26px",
      right: "24px",
      height: "25px",
      width: "25px",
    },
    bmCross: {
      height: "24px",
      background: "#000",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "var(--space-4)",
    },
    bmItem: {
      display: "flex",
      flexDirection: "columns",
      color: "#000000",
      fontSize: "var(--font-large)",
      padding: "4px 0",
      fontFamily: "Roboto, Regular",
      fontWeight: "400",
      marginBottom: "8px"
    },
    bmOverlay: {
      display: "none",
    },
    
  }
  