export const getAllVideosListQuery = `
query GetAllVideo {
    allVideo {
    title
    sub_title
    imageUrl {
        asset {
        url
        }
    }
    video
    }
}
`