import * as React from "react";
import "./Videos.css";
import VideoCard from "./videoCard/videoCard";
import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import useNativeLazyLoading from "@charlietango/use-native-lazy-loading";

const Videos = (props) => {
  const supportsLazyLoading = useNativeLazyLoading();
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "200px 0px",
    skip: supportsLazyLoading !== false,
  });

  const defaultVideo =
    props?.videos && props?.videos?.length > 0 ? props?.videos[0]?.video : "";
  const [videoUrl, setVideoUrl] = useState(defaultVideo);
  const [activeIndex, setActiveIndex] = useState(0);
  const [gridLayout, setGridLayout] = useState(true);

  const updateVideo = (videoUrl, index) => {
    setVideoUrl(videoUrl);
    setActiveIndex(index);
  };
  const playVideo = () => {
    var Video = document.getElementById("techoversityVideo");
    if (Video != null) {
      Video.allow = "autoplay;";
      let iframeSrc = Video.src;
      Video.src = iframeSrc + "&autoplay=1";
    }
  };
  const stopVideo = () => {
    var Video = document.getElementById("techoversityVideo");
    if (Video != null) {
      let iframeSrc = Video.src;
      let url = iframeSrc.split("&autoplay=");
      Video.src = Video.src = url[0] + "&autoplay=0";
      Video.allow = "";
    }
  };
  const updateLayoutType = (value) => {
    setGridLayout(value);
  };
  useEffect(() => {
    playVideo();
  }, [videoUrl]);

  useEffect(() => {
    stopVideo();
  }, []);

  return (
    <>
      <div className="video-tab-container">
        <div className="video-tab_right">
          <div className="techoversity-video-play">
            <>
              {inView || supportsLazyLoading ? (
                <iframe
                  id="techoversityVideo"
                  src={`${videoUrl}?playsinline=1&rel=0&showinfo=0&modestbranding=0`}
                  title="Techoversity Video"
                  frameBorder="0"
                  scrolling="no"
                  className="techoversity_video"
                  allowFullScreen
                ></iframe>
              ) : null}
            </>
          </div>
        </div>
        <div className="video-tab_left">
          <div className="card-layout__option-container">
            <div
              className={`layout-icon layout-grid ${
                gridLayout === true ? "layout-active" : ""
              }`}
              onClick={() => updateLayoutType(true)}
            ></div>
            <div
              className={`layout-icon layout-list ${
                gridLayout !== true ? "layout-active" : ""
              }`}
              onClick={() => updateLayoutType(false)}
            ></div>
          </div>
          <div
            className={`scrollable-content ${
              gridLayout === true ? "" : "video-tab__list"
            }`}
          >
            {props?.videos?.map((values, index) => (
              <VideoCard
                subTitle={true}
                className={`video-card_techoversity ${
                  index == activeIndex ? "video-card__active" : ""
                } ${
                  gridLayout === true ? "video-card__grid" : "video-card__list"
                }`}
                key={index}
                details={values}
                playVideoHandler={updateVideo}
                videoIndex={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Videos;
