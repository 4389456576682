import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import "./PPTdeck.css";

const PPTdeck = ({ pptData }) => {
  return (
    <>
      <a
        className="addppt"
        href="https://one-stop.sanity.studio/desk/powerpoint"
        target="_blank"
        title="Click to open Sanity Studio link"
      >
        Add / Remove
      </a>
      <div className="pptdeck-container">
        {pptData?.map((values, key) => (
          <div key={key} className="pptdeck-card">
            <div className="pptdeck-thumbnail">
              <img src={values?.imageUrl?.asset?.url} alt={values?.title} />
            </div>
            <div className="pptdeck-details">
              <p className="pptdeck-title">{values?.title}</p>
              <p className="pptdeck-desc">{values?.description}</p>
              <a
                href={values?.ppt_url}
                target="_blank"
                className="pptdeck-link"
              >
                <OpenInNewIcon
                  style={{ width: "16px", marginRight: "var(--space-1)" }}
                />
                OPEN PPT
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PPTdeck;
