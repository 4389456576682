import React from "react";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import slack from "../../../images/slack.png";
import gmail from "../../../images/gmail.png";
import defaultLogo from "../../../images/defaultLogo.png";
import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";

const TeamInfo = ({ project, projects }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <div className="tabSection teamInfo-container">
      {projects &&
        projects.map((memberData, key) => {
          if (project?.title === memberData?.title) {
            if (memberData?.projectOwners?.length == 0)
              return (
                <div key={key} className="nocontent-msg">
                  Sorry, no team info to show.
                </div>
              );
            return memberData?.projectOwners?.map((memberInfo, key) => {
              return (
                <div className="contact-card" key={key}>
                  <div className="contact-header">
                    <img
                      src={
                        memberInfo?.image?.asset?.url
                          ? memberInfo?.image?.asset?.url
                          : defaultLogo
                      }
                    />
                    <br />
                    <b>{memberInfo?.name}</b>
                    <br />
                    <span>{memberInfo?.designation}</span>
                  </div>
                  <div
                    className="contact-email"
                    title="Click to copy E-mail Id"
                    onClick={() => {
                      navigator.clipboard.writeText(memberInfo?.email);
                      setSnackbarOpen(true);
                    }}
                  >
                    <img src={gmail} />
                    {memberInfo?.email}
                    <ContentCopyRoundedIcon />
                  </div>
                  <span
                    className="contact-slack"
                    onClick={() => {
                      window.open(
                        `https://slack.com/app_redirect?channel=${memberInfo?.slack}`,
                        "_blank"
                      );
                    }}
                    title="Click to open Slack Channel"
                  >
                    <img src={slack} />@{memberInfo?.name}
                  </span>
                  <Snackbar
                    open={snackbarOpen}
                    sx={{ marginTop: "50px" }}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Alert onClose={handleSnackbarClose} severity="success">
                      Email copied to clipboard!
                    </Alert>
                  </Snackbar>
                </div>
              );
            });
          }
        })}
    </div>
  );
};

export default TeamInfo;
