import React from "react";
import { Card } from "./Cards";

const ClientProjects = ({ clientProjects: projects }) => {
  return (
    <div className="card-container" id="card-list">
      {projects?.length>1 ? projects?.map((project, index) => (
        <div className="card" key={index} onClick={() => {}}>
          <Card project={project} />
        </div>
      )):"Projects not available"}
    </div>
  );
};

export default ClientProjects;
