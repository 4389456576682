import React, { useEffect, useState } from "react";
import { menuStyle } from "./menu-styles";
import "./menu-style.css";
import { slide as Menu } from "react-burger-menu";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Avatar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { signOut } from "@firebase/auth";
import { auth } from "../Firebase/Firebase";

const SideMenu = ({
  handleIsSidebarOpen,
  isSidebarOpen,
  projectNames,
  user,
  setSectionState,
}) => {
  const items =
    projectNames &&
    Object.values(projectNames).map((values, key) => {
      const [title, setTitle] = useState(values?.title);
      const [shouldScroll, setShouldScroll] = useState(false);

      const handleClick = () => {
        setTitle(values?.title);
        setShouldScroll(true);
        setSectionState("demo");
        handleIsSidebarOpen(false)
      };

      useEffect(() => {
        if (shouldScroll) {
          document.getElementById(title + "-id").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          setShouldScroll(false);
        }
      }, [shouldScroll, title]);

      return (
        <div key={key}>
          <div
            className="menu-item"
            onClick={handleClick}
          >
            {values?.title}
          </div>
        </div>
      );
    });
  return (
    <Menu
      className="mobileNav" //scrollable-content
      right
      styles={menuStyle}
      isOpen={isSidebarOpen}
      onOpen={handleIsSidebarOpen}
      onClose={handleIsSidebarOpen}
    >
      <div className="profile-container2">
        <div className="profile-details">
          <div className="profile-info">
            <p>{user?.displayName}</p>
            <p className="userEmail">{user?.email}</p>
          </div>
          <Avatar
            alt="profile image"
            imgProps={{ margin: "auto" }}
            src={user?.photoURL}
          />
        </div>
        <div className="logout" onClick={() => signOut(auth)}>
          <PowerSettingsNewIcon sx={{ mr: 1 }} />
          Log Out
        </div>
      </div>
      <div className="nav_container">
        <div className="nav-sep">Projects</div>
        {/* <FormControl sx={{ ml: 3 }} className="search-input">
          <OutlinedInput
            sx={{ borderRadius: "40px", height: "32px" }}
            placeholder="Search projects"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl> */}
      </div>
      <div className="menu-item-container">{items}</div>
      {/* {items} */}
    </Menu>
  );
};

export default SideMenu;
