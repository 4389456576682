import React from "react";

const FeaturesTab = ({ project }) => {
  return (
    <div className="tabSection" id="features-tab">
      <div className="section1">
        <ul>
          {project?.features?.name
            ? project?.features?.name?.map((item, index) => (
                <li key={index}>{item}</li>
              ))
            : "Sorry, no features to show."}
        </ul>
      </div>
      <div className="section2">
        <div className="img-container">
          <img src={project?.screenshot?.asset?.url}></img>
        </div>
        <p className="detailView-card-title">{project?.title}</p>
      </div>
    </div>
  );
};

export default FeaturesTab;
