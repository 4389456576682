import React from "react";
import "./ScrollBtns.css";

const ScrollBtns = ({ scrollTo, scrollState }) => {
  return (
    <>
      {scrollState ? (
        <div className="scrollDown">
        <div title="Scroll Down"
          className="arrowDown"
          onClick={() => {
            scrollTo(window.innerHeight);
          }}
        ></div>
        </div>
      ) : (
        <div className="scrollTop">
        <div title="Scoll to Top"
          className="arrowTop"
          onClick={() => {
            scrollTo('top');
          }}
        ></div>
        </div>
      )}
    </>
  );
};

export default ScrollBtns;
