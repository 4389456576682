import React, { useState, createRef } from "react";
import SavedNotes from "./SavedNotes";
import NoteEditor from "./NoteEditor";
import Comments from "./Comments";

const SpeakerNotes = ({ project, user }) => {
  const reactQuillRef = createRef();
  const [noteTitle, setNoteTitle] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);

  const [addNote, setAddNote] = useState(false);

  const updateNote = (index) => {
    setAddNote(false);
    setActiveIndex(index);
  };

  return (
    <div className="tabSection" id="speakerNotes-tab">
      <div className="section1" id="notes-comments">
        {/* editor */}
        <NoteEditor
          activeIndex={activeIndex}
          addNote={addNote}
          setAddNote={setAddNote}
          notes={project?.speakerNotes}
          reactQuillRef={reactQuillRef}
          setActiveIndex={setActiveIndex}
          project={project}
          noteTitle={noteTitle}
          setNoteTitle={setNoteTitle}
        />

        {/* comments section */}
        <Comments
          notes={project?.speakerNotes}
          activeIndex={activeIndex}
          user={user}
          projectName={project?.title}
        />
      </div>

      {/* saved notes */}
      <SavedNotes
        notes={project?.speakerNotes}
        activeIndex={activeIndex}
        updateNote={updateNote}
        projectId={project?._id}
        setActiveIndex={setActiveIndex}
        setNoteTitle={setNoteTitle}
      />
    </div>
  );
};

export default SpeakerNotes;
