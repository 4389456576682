import React, { useRef, useState, useEffect, createContext } from "react";
import Nav from "./Header/Nav";
import Cards from "../components/Sections/Cards";
import "./Dashboard.css";
import ScrollBtns from "./Scrollbtns/ScrollBtns";
import { useAuth } from "./Auth/AuthContext";
import { CircularProgress } from "@mui/material";
import Footer from "./Footer/Footer";
import { Helmet } from "react-helmet";
import { getProjectList } from "./service/graphQLService";
import Chat from "./Chat/Chat";

export const UpdateDataContext = createContext();

const Dashboard = () => {
  const containerRef = useRef(null);
  const { user } = useAuth();
  const token = user?.accessToken;
  const scrollTo = (delta) => {
    if (containerRef.current) {
      if (delta == "top") {
        containerRef.current?.firstElementChild?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else
        containerRef.current.scrollBy({
          top: delta - containerRef.current.offsetTop,
          behavior: "smooth",
        });
    }
  };

  const [isSidebarOpen, setIsSideBarOpen] = useState(false);
  const handleIsSidebarOpen = () => {
    setIsSideBarOpen(!isSidebarOpen);
  };

  const [isProjectLoading, setIsProjectLoading] = useState({
    "The Brooklyn Hospital": false,
    "Be There Now": false,
    "Asset monitoring demo (RRS)": false,
    "Techo camp": false,
    "Smart Water Network": false,
    "Smart City": false,
    "Mauritius Telecom": false,
    "Auto AI": false,
    "Autosys to cloud composer": false,
    Martell: false,
  });

  const [projectStatus, setProjectStatus] = useState({
    "The Brooklyn Hospital": "RECONCILING",
    "Be There Now": "RECONCILING",
    "Asset monitoring demo (RRS)": "RECONCILING",
    "Techo camp": "RECONCILING",
    "Smart Water Network": "RECONCILING",
    "Smart City": "RECONCILING",
    "Mauritius Telecom": "RECONCILING",
    "Auto AI": "RECONCILING",
    "Autosys to cloud composer": "RECONCILING",
    Martell: "RECONCILING",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState();
  const [clientProjects, setClientProjects] = useState()

  const fetchData = () => {
    getProjectList()
      .then((result) => {
        const allProjects = result?.data?.allProjectList
        const internalProjects = allProjects?.filter((projectList)=> projectList?.title === "Internal Demo Projects")
        const clientProjects = allProjects?.filter((projectList) => projectList?.title === "Client Projects")
        
        setProjects(internalProjects[0]?.project ?? allProjects[0]?.project ??[]);
        setClientProjects(clientProjects[0]?.project ?? allProjects[1]?.project ??[])
       return internalProjects[0]?.project ?? allProjects[0]?.project
      })
      .then((projects) => {
        projects?.map((el) => {
          getCurrentNodeCount(el);
        });
      })
      .then(() => setIsLoading(false));
  };

  const [downScroll, setDownScroll] = useState(true);
  const footerRef = useRef(null);

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setDownScroll(false);
        } else {
          setDownScroll(true);
        }
      });
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    }
  );

  useEffect(() => {
    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, [footerRef.current]);

  useEffect(() => {
    fetchData();
  }, []);
  // api functions
  const updateNodeCount = (project, isEnable) => {
    setIsProjectLoading((prevState) => ({
      ...prevState,
      [project?.title]: true,
    }));

    const url =
      "https://us-central1-sales-demo-dashboard.cloudfunctions.net/one-stop-demo-App";
    const urlForAssetMgmt =
      "https://us-central1-sales-demo-dashboard.cloudfunctions.net/Enable-Disable-Compute-Instance";

    const reqBodyForAssetMgmt = {
      projectId: "assetmgmtplatform",
      zone: "us-central1-a",
      instanceName: "rss-local-demo-app",
      action: isEnable ? "enable" : "disable",
      auth_token: token,
    };

    const reqBody = {
      projectId: project?.gcpProjectID,
      zone: project?.gcpZone,
      clusterId: project?.gcpClusterId,
      nodePoolId: project?.gcpNodeID,
      nodeCount: isEnable ? 1 : 0,
      locationType: project?.clusterType?.slug?.current,
      email: user?.email,
      auth_token: token,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: JSON.stringify(
        project?.gcpProjectID === "assetmgmtplatform"
          ? reqBodyForAssetMgmt
          : reqBody
      ),
    };
    fetch(
      project?.gcpProjectID === "assetmgmtplatform" ? urlForAssetMgmt : url,
      requestOptions
    )
      .then((response) => response.text())
      .then((data) => {
        console.log(data, "api");
        if (project?.gcpProjectID === "assetmgmtplatform") {
          setTimeout(() => {
            getProjectStatus(project);
          }, 30000);
        } else {
          getProjectStatus(project);
        }
      });
  };

  const enableDisableProject = (project, event) => {
    projects?.map((item) => {
      if (item?.title == project?.title) {
        item.isEnabled = event;
      }
    });
    updateNodeCount(project, event);
  };

  // get project status
  const getProjectStatus = (project) => {
    const reqBodyForAssetMgmt = {
      projectId: "assetmgmtplatform",
      zone: "us-central1-a",
      instanceName: "rss-local-demo-app",
      action: "status",
      auth_token: token,
    };

    const reqBody = {
      authenticationEmail: user?.email,
      projectName: project?.gcpProjectID,
      clusterName: project?.gcpClusterId,
      clusterLocation: project?.gcpZone,
      auth_token: token,
    };

    const url =
      "https://us-central1-sales-demo-dashboard.cloudfunctions.net/One-Stop-Demo-Project-Status";

    const urlForAssetMgmt =
      "https://us-central1-sales-demo-dashboard.cloudfunctions.net/Enable-Disable-Compute-Instance";

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: JSON.stringify(
        project?.gcpProjectID === "assetmgmtplatform"
          ? reqBodyForAssetMgmt
          : reqBody
      ),
    };

    try {
      fetch(
        project?.gcpProjectID === "assetmgmtplatform" ? urlForAssetMgmt : url,
        requestOptions
      )
        .then((response) => response.text())
        .then((data) => {
          if (project?.gcpNodeID) {
            data = JSON.parse(data);
            project["isEnabled"] =
              (data?.projectStatus === "RUNNING" && data?.nodeCount > 0) ||
              data?.instanceStatus === "RUNNING"
                ? true
                : false;
            setProjectStatus((prevState) => ({
              ...prevState,
              [project?.title]: data?.projectStatus || data?.instanceStatus,
            }));
            if (
              data?.projectStatus === "RECONCILING" ||
              data?.instanceStatus === "STOPPING" ||
              data?.instanceStatus === "STAGING"
            ) {
              setTimeout(() => {
                getProjectStatus(project);
              }, 30000);
            }
          } else {
            setProjectStatus((prevState) => ({
              ...prevState,
              [project?.title]: "error",
            }));
          }
        })
        .finally(() =>
          setIsProjectLoading((prevState) => ({
            ...prevState,
            [project?.title]: false,
          }))
        );
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentNodeCount = (project) => {
    getProjectStatus(project);
  };

  //header tab section
  const [sectionState, setSectionState] = useState("demo");

  return (
    <UpdateDataContext.Provider value={fetchData}>
      <Helmet>
        <title>One Stop Demo Shop</title>
      </Helmet>
      {isLoading ? (
        <div
          style={{
            color: "#da3562",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress style={{ color: "#da3562" }} />
          <p>Loading please wait</p>
        </div>
      ) : (
        <div>
          <Nav
            projects={projects}
            isSidebarOpen={isSidebarOpen}
            handleIsSidebarOpen={handleIsSidebarOpen}
            scrollTo={scrollTo}
            setSectionState={setSectionState}
          />
          <ScrollBtns
            scrollTo={scrollTo}
            scrollState={downScroll ? true : false}
          />
          <div ref={containerRef} className="mainContainer">
            <Cards
              user={user}
              projects={projects}
              updateNode={enableDisableProject}
              isProjectLoading={isProjectLoading}
              projectStatus={projectStatus}
              sectionState={sectionState}
              setSectionState={setSectionState}
              clientProjects={clientProjects}
            />
            <div ref={footerRef}>
              <Footer
                projectNames={projects}
                setSectionState={setSectionState}
              />
            </div>
          </div>
            <Chat />
        </div>
      )}
    </UpdateDataContext.Provider>
  );
};

export default Dashboard;
