const url = `https://sanity-api-xi.vercel.app/api/query`;

export const getData = async (query) => {
  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query,
    }),
  });
  return res.json();
};
