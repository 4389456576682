import React from "react";
import "./Nav.css";
import logo from "../../images/techo-logo.svg";
import SideMenu from "../Menu/SideMenu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { signOut } from "@firebase/auth";
import { auth } from "../Firebase/Firebase";
import { useAuth } from "../Auth/AuthContext";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Avatar, Button } from "@mui/material";

const Header = ({ projects, isSidebarOpen, handleIsSidebarOpen, scrollTo, setSectionState }) => {
  const { user } = useAuth();
  return (
    <div className="header">
      {/* project logo */}
      <div
        className="logoContainer"
        onClick={(_event) => {
          const cardsContainer = document.getElementById("card-list");
          if (cardsContainer) {
            cardsContainer.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
          scrollTo("top");
        }}
      >
        <img src={logo}></img>
        <p>
          <span style={{ fontWeight: "600" }}> One Stop</span> Demo Shop
        </p>
      </div>
      <div className="middleContainer">
          <Button variant="contained" endIcon={<OpenInNewIcon/>} className="sanity-button" onClick={()=>window.open("https://one-stop.sanity.studio/")}>
            Add/ Edit content
          </Button>
        
        <div className="profile-container">
          <div className="profile-details">
            <div className="profile-info">
              <p>{user?.displayName}</p>
              <p className="userEmail">{user?.email}</p>
            </div>
            <Avatar
              alt="profile image"
              imgProps={{ margin: "auto" }}
              src={user?.photoURL}
            />
          </div>
          <div className="logout" onClick={() => signOut(auth)}>
            <PowerSettingsNewIcon sx={{ mr: 1 }} />
            Logout
          </div>
        </div>
        {/* project side menu */}
        <div className="sideMenu">
          <SideMenu
            handleIsSidebarOpen={handleIsSidebarOpen}
            isSidebarOpen={isSidebarOpen}
            projectNames={projects}
            user={user}
            setSectionState={setSectionState}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
