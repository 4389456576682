import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import Status from "../components/Status"
import Dashboard from "../components/Dashboard"

const App = () => (
  <Layout>
    <Status />
    <Router>
      <PrivateRoute path="/app/dashboard" component={Dashboard} />
      <Login path="/app/login" />
    </Router>
  </Layout>
)

export default App