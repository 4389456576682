import React, { useState, useEffect } from "react";
import "./Cards.css";
import { CircularProgress } from "@mui/material";
import DetailView from "./DetailView";
import { getPowerPointList, getVideoList } from "../service/graphQLService";
import Assets from "./Assets";
import ClientProjects from "./ClientProjects";

export const Card = ({ project }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#f2f3f4",
          borderRadius: "var(--space-3)",
          padding: "var(--space-1) var(--space-2)",
        }}
      >
        <img src={project?.screenshot?.asset?.url}></img>
      </div>
      <div className="title">{project?.title}</div>
      <div
        className={
          project?.isEnabled || project?.gcpCannotDisable
            ? "projStatusIcon"
            : "projStatusIconDisabled "
        }
      >
        <div className="projStatusMsg">
          {project?.isEnabled || project?.gcpCannotDisable
            ? "This Project is Enabled"
            : "This Project is Disabled"}
        </div>
      </div>
    </>
  );
};

const Cards = ({
  user,
  projects,
  updateNode,
  isProjectLoading,
  projectStatus,
  sectionState,
  setSectionState,
  clientProjects
}) => {
  const sectionList = ["Demo", "Client Projects", "Assets"];
  const sections = sectionList.map((values, key) => (
    <div
      className={sectionState === values.toLowerCase() ? "selectedTab" : "tab"}
      key={key}
      onClick={() => setSectionState(values.toLowerCase())}
    >
      {values.toUpperCase()}
    </div>
  ));

  const [videos, setVideos] = useState();
  const [ppt, setPpt] = useState();

  useEffect(() => {
    getVideoList().then((result) => setVideos(result?.data?.allVideo));
    getPowerPointList().then((result) => setPpt(result?.data?.allPowerpoint));
  }, []);

  return (
    <>
      {sectionState === "demo" && (
        <>
          <div
            className="projectContainer"
            style={{ flexDirection: "column", justifyContent: "flex-start" }}
          >
            <div className="sectionsHeader">{sections}</div>
            <div className="card-container" id="card-list">
              {projects ? (
                projects.map((project, index) => (
                  <div
                    className="card"
                    key={index}
                    onClick={() => {
                      document
                        .getElementById(project?.title + "-id")
                        .scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <Card project={project} />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    color: "#da3562",
                    position: "absolute",
                    top: "50%",
                    left: "47%",
                    margin: "auto",
                  }}
                >
                  <CircularProgress style={{ color: "#da3562" }} />
                  <p>Loading please wait</p>
                </div>
              )}
            </div>
          </div>
          <DetailView
            user={user}
            projects={projects}
            updateNode={updateNode}
            isProjectLoading={isProjectLoading}
            projectStatus={projectStatus}
          />
        </>
      )}
      {sectionState === "client projects" && (
        <div
          className="projectContainer"
          style={{ flexDirection: "column", justifyContent: "flex-start" }}
        >
          <div className="sectionsHeader">{sections}</div>
          <ClientProjects clientProjects={clientProjects}/>
        </div>
      )}
      {sectionState === "assets" && (
        <div
          className="projectContainer"
          style={{ flexDirection: "column", justifyContent: "flex-start" }}
        >
           <div className="sectionsHeader">{sections}</div>
          <Assets  videos={videos} ppt={ppt}/>
        </div>)}
      
      
    </>
  );
};

export default Cards;
