import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { auth } from "./Firebase/Firebase";
import { signOut } from "firebase/auth";
import "./Login.css";
import WhiteLogo from "../images/Techo-logo-white.svg";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/dashboard",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with google'
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      setTimeout(() => navigate("/app/dashboard/"), 1000);
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return false;
    },
  },
};

const Login = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setCurrentUser(user); // why not just use user in auth context?
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);
  useEffect(() => {
    if (currentUser) {
      setAccessToken(currentUser.accessToken);
      navigate("/app/dashboard");
    }
  }, [currentUser]);

  return (
    <>
      {!isSignedIn ? (
        <div className="login-container">
          <div className="login-logo">
            <img src={WhiteLogo} alt="white-logo" />{" "}
            <p>
              <span>One Stop</span> Demo Shop
            </p>
          </div>
          <div className="login-box">
            <p>Welcome to One stop demo shop</p>
            <div>
              <p>Log in</p>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} className="login-button"/>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h1>My App</h1>
          <p>Welcome {currentUser?.displayName}! You are now signed-in!</p>
          <a
            href="/"
            onClick={(event) => {
              event.preventDefault();
              signOut(auth);
              navigate(`/app/login`);
            }}
          >
            log out
          </a>
          <div>
            <Link to="/app/dashboard">Dashboard</Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
