import React, { useContext, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LinearProgress, Menu, MenuItem } from "@mui/material";
import { handleDeleteNoteMutation } from "./helper";
import { UpdateDataContext } from "../../../Dashboard";

const SavedNotes = ({
  notes,
  activeIndex,
  updateNote,
  projectId,
  setActiveIndex,
  setNoteTitle,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNoteIndex, setSelectedNoteIndex] = useState(null);
  const updateData = useContext(UpdateDataContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedNoteIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedNoteIndex(null);
  };

  const handleDeleteNote = async () => {
    setIsLoading(true);
    try {
      handleMenuClose();
      const noteToDelete = notes[selectedNoteIndex];
      const noteIdToBeDeleted = noteToDelete?._id;
      await handleDeleteNoteMutation(noteIdToBeDeleted, projectId)
        .then(() => updateData())
        .then(() => {
          setSelectedNoteIndex(null);
          setIsLoading(false);
          setActiveIndex(null);
          setNoteTitle("");
        });
    } catch (error) {
      console.log("Error deleting note:", error);
    }
  };

  const handleEditNote = () => {
    setNoteTitle(notes[selectedNoteIndex]?.title || "");
    updateNote(selectedNoteIndex);
    handleMenuClose();
  };

  return (
    <div className="section2" id="saved-notes">
      <div className="titlesSummary">Saved Notes</div>
      {isLoading && (
        <LinearProgress
          sx={{
            marginBottom: 2,
            background: "#da5362",
            "& .MuiLinearProgress-bar1Indeterminate": {
              backgroundColor: "#A2A2A2",
            },
            "& .MuiLinearProgress-bar2Indeterminate": {
              backgroundColor: "#da5362",
            },
          }}
        />
      )}
      <div className="all-saved-notes">
        {notes?.map((note, key) => (
          <div
            key={key}
            className={`notes-card ${
              activeIndex === key ? "notes-card__active" : ""
            }`}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p>
                {note?.title && note.title.length > 30
                  ? note.title.slice(0, 30) + "..."
                  : note?.title}
              </p>
              <span
                style={{
                  width: "100%",
                  height: "var(--space-3)",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {note?.content && note.content.length > 30
                  ? note.content.slice(0, 30) + "..."
                  : note?.content}
              </span>

              <span
                style={{ whiteSpace: "nowrap", paddingTop: "var(--space-1)" }}
              >
                {note?.lastUpdatedTime}
              </span>
            </div>
            <div style={{ cursor: "pointer" }}>
              <MoreVertIcon onClick={(e) => handleMenuOpen(e, key)} />
            </div>
          </div>
        ))}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditNote}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteNote}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

export default SavedNotes;
